/*
 * @Description: 
 * @Version: 1.0
 * @Autor: liuhaoyu
 * @Date: 2022-03-24 13:43:00
 * @LastEditors: liuhaoyu
 * @LastEditTime: 2022-03-26 13:00:11
 */
// 搜索页和楼盘的名称列表
import request from '@/request/request'
export function all(data){
    return request({
        url:'/Property/Maintenance/List',
        method:'post',
        data
    })
}
export function list(data){
    return request({
        url:'/Property/HousesSelectList',
        method:'post',
        data
    })
}

